
import { defineComponent } from 'vue';
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import { mapActions, useStore } from 'vuex';
import { destroyToken } from '@/helpers/localStorage';
import constRouter from '@/constants/constRouter';
import { hiddenNumber } from '@/utils';

export default defineComponent({
  name: 'Profile',
  components: { DefaultLayout },
  setup() {
    const store = useStore();
    const user = store.getters.getUser;

    return {
      DEFAULT_AVATAR: require('@/assets/images/avartar.png'),
      selfieImage: user.facePhotoImageUrl,
      fullName: user.fullName,
      phoneNumber: user.phoneNumber,
      nicNumber: user.nicNumber || '_',
      birthday: user.birthday || '_',
      address: user.address?.label || '_',
      hiddenNumber,
    };
  },
  methods: {
    ...mapActions({
      logoutState: 'logout',
    }),
    logout() {
      // 1. destroy use/ access token
      destroyToken();
      // LocalStorage.destroyUser();
      // 2. clear state
      this.logoutState();
      // 3. push login
      this.$router.push({ name: constRouter.LOGIN.name });
    },
    handleClick() {
      this.$router.push({
        name: constRouter.EKYC_VIEW.name,
      });
    },
  },
});
