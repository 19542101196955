<template>
  <default-layout :show-header="false" show-footer>
    <div class="action-bar pt-4">
      <div class="edit text-right">
        <!--        <span>-->
        <!--          edit-->
        <!--        </span>-->
      </div>
    </div>
    <div class="avatar-block my-4 flex flex-col items-center text-center">
      <div class="avatar flex relative flex-col items-center justify-center">
        <div
          class="img-wrap img-box overflow-hidden bg-gray-400"
          :class="{ 'text-iconColor': !selfieImage }"
        >
          <img
            :src="selfieImage ? selfieImage : DEFAULT_AVATAR"
            alt="avatar"
            class="w-full"
          />
        </div>
      </div>
      <div class="avatar-info mt-2">
        <div class="avatar-info_name">{{ fullName }}</div>
        <div class="avatar-info_phone text-sm text-iconColor">
          {{ hiddenNumber(phoneNumber) }}
        </div>
      </div>
    </div>
    <div class="content mx-7">
      <div class="a-element items-center">
        <div class="left-col">
          <span class="mr-2 text-green-600">
            <svg
              class="w-6 h-6"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <path
                d="M22 3H2c-1.09.04-1.96.91-2 2v14c.04 1.09.91 1.96 2 2h20c1.09-.04 1.96-.91 2-2V5a2.074 2.074 0 0 0-2-2m0 16H2V5h20v14m-8-2v-1.25c0-1.66-3.34-2.5-5-2.5c-1.66 0-5 .84-5 2.5V17h10M9 7a2.5 2.5 0 0 0-2.5 2.5A2.5 2.5 0 0 0 9 12a2.5 2.5 0 0 0 2.5-2.5A2.5 2.5 0 0 0 9 7m5 0v1h6V7h-6m0 2v1h6V9h-6m0 2v1h4v-1h-4"
              />
            </svg>
          </span>
          CMND
        </div>
        <div class="right-col">{{ nicNumber }}</div>
      </div>
      <div class="a-element items-center">
        <div class="left-col">
          <span class="mr-2 text-yellow-600">
            <svg
              class="w-6 h-6"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <path
                d="M15 13h1.5v2.82l2.44 1.41l-.75 1.3L15 16.69V13m4-5H5v11h4.67c-.43-.91-.67-1.93-.67-3a7 7 0 0 1 7-7c1.07 0 2.09.24 3 .67V8M5 21a2 2 0 0 1-2-2V5c0-1.11.89-2 2-2h1V1h2v2h8V1h2v2h1a2 2 0 0 1 2 2v6.1c1.24 1.26 2 2.99 2 4.9a7 7 0 0 1-7 7c-1.91 0-3.64-.76-4.9-2H5m11-9.85A4.85 4.85 0 0 0 11.15 16c0 2.68 2.17 4.85 4.85 4.85A4.85 4.85 0 0 0 20.85 16c0-2.68-2.17-4.85-4.85-4.85z"
              />
            </svg>
          </span>
          Ngày sinh
        </div>
        <div class="right-col">{{ birthday }}</div>
      </div>
      <div class="a-element items-center">
        <div class="left-col">
          <span class="mr-2 text-blue-600">
            <svg
              class="w-6 h-6"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <path
                d="M12 3L2 12h3v8h14v-8h3L12 3m0 4.7c2.1 0 3.8 1.7 3.8 3.8c0 3-3.8 6.5-3.8 6.5s-3.8-3.5-3.8-6.5c0-2.1 1.7-3.8 3.8-3.8m0 2.3a1.5 1.5 0 0 0-1.5 1.5A1.5 1.5 0 0 0 12 13a1.5 1.5 0 0 0 1.5-1.5A1.5 1.5 0 0 0 12 10z"
              />
            </svg>
          </span>
          Địa chỉ
        </div>
        <div class="right-col">{{ address }}</div>
      </div>
      <div class="a-element items-center" @click="handleClick">
        <div class="left-col cursor-pointer">
          <span class="mr-2 text-indigo-700">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
              />
            </svg>
          </span>
          Hình ảnh của bạn
        </div>
      </div>
      <div
        class="logout flex text-red-500 font-medium mt-6 items-center justify-center cursor-pointer"
        @click="logout"
      >
        <span class="mr-1">
          <svg
            class="w-6 h-6"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            <g>
              <path
                d="M2 6a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v2a1 1 0 1 1-2 0V6H4v12h9v-2a1 1 0 1 1 2 0v2a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6zm15.293 2.293a1 1 0 0 1 1.414 0l3 3a1 1 0 0 1 0 1.414l-3 3a1 1 0 0 1-1.414-1.414L18.586 13H9a1 1 0 1 1 0-2h9.586l-1.293-1.293a1 1 0 0 1 0-1.414z"
              />
            </g>
          </svg>
        </span>
        <p class="text-lg">Đăng xuất</p>
      </div>
    </div>
  </default-layout>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import { mapActions, useStore } from 'vuex';
import { destroyToken } from '@/helpers/localStorage';
import constRouter from '@/constants/constRouter';
import { hiddenNumber } from '@/utils';

export default defineComponent({
  name: 'Profile',
  components: { DefaultLayout },
  setup() {
    const store = useStore();
    const user = store.getters.getUser;

    return {
      DEFAULT_AVATAR: require('@/assets/images/avartar.png'),
      selfieImage: user.facePhotoImageUrl,
      fullName: user.fullName,
      phoneNumber: user.phoneNumber,
      nicNumber: user.nicNumber || '_',
      birthday: user.birthday || '_',
      address: user.address?.label || '_',
      hiddenNumber,
    };
  },
  methods: {
    ...mapActions({
      logoutState: 'logout',
    }),
    logout() {
      // 1. destroy use/ access token
      destroyToken();
      // LocalStorage.destroyUser();
      // 2. clear state
      this.logoutState();
      // 3. push login
      this.$router.push({ name: constRouter.LOGIN.name });
    },
    handleClick() {
      this.$router.push({
        name: constRouter.EKYC_VIEW.name,
      });
    },
  },
});
</script>

<style lang="scss" scoped>
.avatar {
  .img-wrap {
    width: 80px;
    height: 80px;

    &.img-box {
      box-shadow: 0 0 0 2px rgb(0 0 0 / 12%);
    }

    border-radius: 50%;
  }
}

.a-element {
  background: white;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid gray;
  padding: 16px 0;
  justify-content: space-between;

  .left-col {
    width: 50%;
    display: flex;
    align-items: center;

    .mr-2 {
      margin-right: 7px;
    }
  }

  .right-col {
    text-align: right;
  }

  .text-info {
    display: flex;
    flex-direction: column;
  }
}
</style>
